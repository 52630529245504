body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Quicksand Light',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@font-face {
  font-family: 'quicksand-regular';
  src: local('MyFont'), url(../public/assets/fonts/Quicksand/static/Quicksand-Regular.ttf) format('woff2');
  
}


@font-face {
  font-family: 'quicksand-bold';
  src: local('MyFont'), url(../public/assets/fonts/Quicksand/static/Quicksand-Bold.ttf) format('woff2');
  
}
@font-face {
  font-family: 'quicksand-regular';
  src: local('MyFont'), url(../public/assets/fonts/Quicksand/static/Quicksand-Regular.ttf) format('woff2');
  
}

@font-face{
  font-family:'sourceSansPro-light' ;
  src: local('MyFont'), url(../public/assets/fonts/source_sans_pro/SourceSansPro-Light.ttf) format('woff2');
}
@font-face{
  font-family:'sourceSansPro-bold' ;
  src: local('MyFont'), url(../public/assets/fonts/source_sans_pro/SourceSansPro-Bold.ttf) format('woff2');
}

@font-face{
  font-family:'sourceSansPro-regular' ;
  src: local('MyFont'), url(../public/assets/fonts/source_sans_pro/SourceSansPro-Regular.ttf) format('woff2');
}
@font-face {
  font-family: 'quicksand-medium';
  src: local('MyFont'), url(../public/assets/fonts/Quicksand/static/Quicksand-Medium.ttf) format('woff2');
  
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
